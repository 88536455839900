import { Box, Container, Divider, Paper, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Rank() {
	const apiUrl = process.env.REACT_APP_API;

	useEffect(() => {
		gerRanks();
	}, []);
	const [ranks, setRanks] = useState([]);
	const gerRanks = async () => {
		try {
			const response = await axios.get(`${apiUrl}/user?rank-list`);
			if (response.data.status === true) {
				setRanks(response.data.data);
			} else {
				setRanks(response.data);
			}
		} catch (error) {
			console.error("Error:", error);
		}
	};

	return (
		<Box className="content" pb={10}>
			<Container>
				<Box textAlign={"center"} sx={{ background: "#F7F7F7" }} borderRadius={2} mb={1}>
					<img src="/img/success.png" style={{ width: "270px" }} />
				</Box>
				<Box mb={1} textAlign={"center"}>
					<Typography variant="body1" fontSize={12} color={"gray"}>
						Ranking list updates every week!
					</Typography>
					<Typography variant="body1" fontWeight={"bold"} color={"primary.main"}>
						Happy Earning!
					</Typography>
				</Box>
				{ranks.status !== false
					? ranks.map((row, i) => (
							<Box key={i} component={Paper} p={1} mt={1}>
								<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
									<Box>
										<img src={`/img/level${row.rank}.png`} />
									</Box>
									<Box textAlign={"end"}>
										<Typography fontSize={15} fontWeight={"bold"}>
											{row.userid}
										</Typography>
										<Typography fontSize={12} color={"gray"}>
											{row.name} From {row.city}, {row.state}
										</Typography>
									</Box>
								</Stack>
								<Divider sx={{ fontSize: 10, color: "gray" }}>Earnings</Divider>
								<Stack direction={"row"} justifyContent={"space-between"}>
									<Box color={"red"}>
										<Typography fontSize={8}>Recharge</Typography>
										<Typography fontWeight={"bold"}>&#8377; {Number(row.total_recharge).toLocaleString()}</Typography>
									</Box>
									<Box textAlign={"end"} color={"green"}>
										<Typography fontSize={8}>Withdraw</Typography>
										<Typography fontWeight={"bold"}>&#8377; {Number(row.total_withdraw).toLocaleString()}</Typography>
									</Box>
								</Stack>
							</Box>
					  ))
					: null}
			</Container>
		</Box>
	);
}
