import { CloudUpload } from "@mui/icons-material";
import { Alert, Box, Button, Container, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import MyContext from "./../context/context";

export default function Community({ snackbar }) {
	const api = useContext(MyContext);
	const [action, setAction] = useState("");

	const [clink, setLink] = useState("");
	useEffect(() => {
		community_link();
	}, []);

	const community_link = async () => {
		try {
			const response = await axios.get(`${api.api.admin}?community`);
			if (response.data.status === true) {
				setLink(response.data.data);
			} else {
				setLink(response.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const [newLink, setNewLink] = useState("");
	const update_link = async () => {
		try {
			setAction("updating");
			let formData = new FormData();
			formData.append("update-community-link", clink.community_id);
			formData.append("link", newLink);
			const response = await axios.post(api.api.admin, formData);
			snackbar(response.data.msg);
			if (response.data.status === true) {
				setAction("");
				community_link();
			} else {
				setAction("");
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Box className="content">
			<Container>
				<Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
					<TextField size="small" fullWidth variant="outlined" label="Upload community link..." value={newLink} onChange={(event) => setNewLink(event.target.value)} />
					<Button variant="contained" startIcon={<CloudUpload />} onClick={() => update_link()} disabled={action === "updating" ? true : false}>
						Upload
					</Button>
				</Stack>

				<Typography mt={3} textAlign={"center"} variant="body2" color={"gray"}>
					Join this community
				</Typography>
				{clink.status === false ? (
					<Alert severity="error">{clink.msg}</Alert>
				) : (
					<Box textAlign={"center"} mt={1}>
						<a href={clink.link} target="_blank">
							{clink.link}
						</a>
					</Box>
				)}
			</Container>
		</Box>
	);
}
