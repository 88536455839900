import Cookies from "js-cookie"
import React, { useContext, useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import MyContext from "../context/context"

// MUI
import { ArrowBack, BuildCircle, Face, Info, PowerSettingsNew, RocketLaunch } from "@mui/icons-material"
import { AppBar, BottomNavigation, BottomNavigationAction, Box, Button, CircularProgress, Dialog, DialogContent, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Toolbar, Typography } from "@mui/material"
import axios from "axios"

export default function SidebarUser() {
	const api = useContext(MyContext)
	const navi = useNavigate()
	const auth = Cookies.get("authuser")
	const [open, setOpen] = useState("")
	const [action, setAction] = useState("")
	let location = useLocation()

	useEffect(() => {
		axios.get(`${api.api.cookie}`)
	}, [])

	useEffect(() => {
		auth !== "user" && navi("/login-user")
	}, [])

	const link = [
		{ name: "Home", link: "/user/home", icon: <RocketLaunch /> },
		{ name: "About", link: "/user/about", icon: <Info /> },
		{ name: "Engine", link: "/user/device", icon: <BuildCircle /> },
		{ name: "Profile", link: "/user/profile", icon: <Face /> },
	]

	const [value, setValue] = React.useState(3)
	useEffect(() => {
		const storedValue = localStorage.getItem("activeButtonIndex")
		if (storedValue !== null) {
			setValue(parseInt(storedValue))
		}
	}, [])

	const change_btn_color = (event, newValue) => {
		setValue(newValue)
		localStorage.setItem("activeButtonIndex", newValue)
	}

	const logout = () => {
		setAction("loggingout")
		axios.get(`${api.api.user}?logout`).then(function (res) {
			setAction("")
			res.data.status === true && navi("/login-user", { replace: true })
		})
	}

	return (
		<>
			<Dialog open={open === "logout" ? true : false} fullWidth maxWidth="xs" onClose={() => setOpen("")}>
				<DialogContent>
					<Box textAlign={"center"}>
						<Typography color={"primary.main"} fontWeight={600} fontSize={33}>
							Do you want to logout?
						</Typography>
						<Typography fontSize={12} color={"gray"}>
							*This process will redirect you to the login page
						</Typography>
						<Typography fontSize={12} color={"gray"} mb={2}>
							*You can't undo this process
						</Typography>

						<Button variant="contained" color="primary" disabled={action === "loggingout" ? true : false} onClick={() => logout()}>
							{action === "loggingout" ? <CircularProgress color="inherit" size={24} /> : "Logout Now"}
						</Button>
					</Box>
				</DialogContent>
			</Dialog>
			<AppBar
				position="sticky"
				sx={{
					backgroundImage: "linear-gradient(270deg, #046A38, #FFFFFF, #FF671F)",
					/*backgroundImage: "linear-gradient(90deg, #007FFF, #00BFFF)"*/
				}}
			>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={() => navi(-1)}>
						<ArrowBack />
					</IconButton>
					<Typography fontSize={20} className="font-anta">
						Aircraft-MFG
					</Typography>
					<Typography sx={{ flexGrow: 1 }} />
					<IconButton color="inherit" onClick={() => setOpen("logout")}>
						<PowerSettingsNew />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Paper sx={{ display: { xs: "block", md: "none" }, position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1000 }} elevation={3}>
				<BottomNavigation
					showLabels
					value={value}
					onChange={(event, newValue) => {
						change_btn_color(event, newValue)
					}}
				>
					{link.map((row, i) => (
						<BottomNavigationAction label={row.name} icon={row.icon} key={i} onClick={() => navi(row.link)} />
					))}
				</BottomNavigation>
			</Paper>

			<Drawer
				variant="permanent"
				sx={{
					display: { xs: "none", sm: "block" },
				}}
				PaperProps={{ style: { width: "256px" } }}
			>
				<Stack direction={"row"} alignItems={"center"} justifyContent={"center"} height={63} borderBottom={"thin solid"} borderColor={"primary.main"}>
					<Typography textAlign={"center"} color={"primary"} fontSize={25} mr={1} className="font-anta">
						Aircraft-MFG
					</Typography>
					<RocketLaunch color="primary" />
				</Stack>
				<List>
					{link.map((row, i) => (
						<ListItem key={i}>
							<ListItemButton onClick={() => navi(row.link)}>
								<ListItemIcon>{row.icon}</ListItemIcon>
								<ListItemText>{row.name}</ListItemText>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Drawer>
			<Outlet />
		</>
	)
}
