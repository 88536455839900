import { ShoppingCart } from "@mui/icons-material"
import { Box, Button, Chip, Container, Divider, Grid, Paper, Stack, Typography } from "@mui/material"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

const Home = () => {
	const apiUrl = process.env.REACT_APP_API
	let navigate = useNavigate()
	const category = [
		{ name: "PENDRIVE", image: "pendrive.png", start_price: 300 },
		{ name: "MOUSE", image: "mouse.png", start_price: 500 },
		{ name: "KEYBOARD", image: "keyboard.png", start_price: 400 },
		{ name: "SPEAKER", image: "speaker.png", start_price: 1200 },
	]

	const scrollToSection = (id) => {
		const target = document.getElementById(id)
		if (target) {
			target.scrollIntoView({ behavior: "smooth" })
		}
	}

	useEffect(() => {
		getProducts()
	}, [])

	const [pendrive, setPendrive] = useState([])
	const getProducts = async () => {
		try {
			const response = await axios.get(`${apiUrl}/public?action=get-products&category=pendrive`)
			if (response.data.status === true) {
				setPendrive(response.data.data)
			} else {
				setPendrive(response.data)
			}
		} catch (error) {
			setPendrive([])
		}
	}

	return (
		<Box pb={5}>
			<Box
				sx={{
					backgroundColor: "#f9f9f9",
					pb: 4,
				}}
			>
				<Container>
					<Grid spacing={2} direction={{ xs: "column-reverse", md: "row" }} container alignItems="center">
						<Grid item xs={12} md={8}>
							<Box>
								<Typography variant="h3" fontWeight={"bold"}>
									Precision in Every Click
								</Typography>
								<Typography mt={2} variant="body2">
									Experience seamless control, unparalleled comfort, and next-level performance with <br /> DELL Mouse. Designed for gamers, creators, and <br /> professionals who demand the best
								</Typography>
								<Stack direction={"row"} useFlexGap flexWrap={"wrap"} spacing={1} mt={3}>
									<Chip sx={{ borderRadius: 1 }} label="Ergonomic Design" />
									<Chip sx={{ borderRadius: 1 }} label="Ultra-Responsive Sensor" />
									<Chip sx={{ borderRadius: 1 }} label="Customizable Buttons" />
								</Stack>
								<Box mt={3}>
									<Button onClick={() => scrollToSection("shophere")} variant="contained" color="primary">
										Shop Now
									</Button>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} md={4}>
							<Box textAlign={{ xs: "center", md: "center" }}>
								<img src="/img/shop_img/mouse_hero.png" alt="Mouse" style={{ width: "200px" }} />
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Container>
				<Grid mt={3} container spacing={2}>
					{category.map((row, i) => (
						<Grid key={i} item xs={12} md={3}>
							<Box p={2} textAlign={"center"} sx={{ background: "#f9f9f9" }} borderRadius={3}>
								<img src={`/img/shop_img/${row.image}`} alt="iPhone" style={{ width: "200px" }} />
								<Typography fontSize={14} fontWeight={"bold"}>
									{row.name}
								</Typography>
								<Typography>Price starts from &#8377; {Number(row.start_price).toLocaleString()}</Typography>
							</Box>
						</Grid>
					))}
				</Grid>
			</Container>

			<Container id="shophere">
				<Box mt={3} mb={1}>
					<Typography variant="h6" color={"primary"}>
						Explore Pendrive
					</Typography>
				</Box>
				<Grid container spacing={2}>
					{pendrive.map((row, i) => (
						<Grid item xs={12} md={3} key={i}>
							<Box elevation={1} component={Paper} borderRadius={3} sx={{ overflow: "hidden" }}>
								<Box sx={{ background: "#f9f9f9" }} pl={4} pr={4}>
									<img src={`../aircraftassets/img/products/${row.product_img}`} alt="pendrive" style={{ width: "200px" }} />
								</Box>
								<Box mb={1} pl={2} pr={2} mt={1} height={120}>
									<Typography
										fontSize={13}
										fontWeight="bold"
										sx={{
											display: "-webkit-box",
											WebkitLineClamp: 3,
											WebkitBoxOrient: "vertical",
											overflow: "hidden",
										}}
									>
										{row.name}
									</Typography>
									<Typography
										variant="caption"
										color="gray"
										mt={1}
										sx={{
											display: "-webkit-box",
											WebkitLineClamp: 3,
											WebkitBoxOrient: "vertical",
											overflow: "hidden",
										}}
									>
										{row.descriptions}
									</Typography>
								</Box>
								<Divider />
								<Box pb={2} pl={2} pr={2} mt={2}>
									<Stack mt={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
										<Typography variant="h6" fontWeight={"bold"}>
											&#8377; {Number(row.offer_price).toLocaleString()}
											<Typography ml={1} fontWeight={"bold"} fontSize={14} textTransform={"full-size-kana"} variant="span">
												&#8377; {Number(row.offer_price).toLocaleString()}
											</Typography>
										</Typography>
										<Button onClick={() => navigate(`/product-details`)} variant="contained" color="primary" startIcon={<ShoppingCart />}>
											Buy now
										</Button>
									</Stack>
								</Box>
							</Box>
						</Grid>
					))}
				</Grid>
			</Container>
		</Box>
	)
}

export default Home
