import React, { useContext, useEffect, useState } from "react";
import {} from "react-router-dom";

// MUI
import { CloudUpload, Create } from "@mui/icons-material";
import { Box, Button, CircularProgress, Container, Dialog, DialogContent, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import Lottie from "lottie-react";
import MyContext from "../../context/context";
import cancelAnimation from "./../../lottie/cancel.json";
import reivewAnimation from "./../../lottie/review.json";
import successAnimation from "./../../lottie/success.json";

export default function ContractUser({ snackbar }) {
	const api = useContext(MyContext);
	const [working, setWorking] = useState("");
	const [open, setOpen] = useState("");

	const formattedDate = new Date().toLocaleDateString("en-in").split("/").reverse().join("-");

	const [profile, setProfile] = useState([]);
	useEffect(() => {
		getprofile();
	}, []);

	const getprofile = () => {
		axios.get(`${api.api.user}?profile`).then(function (res) {
			setProfile(res.data.profile.data);
			if (res.data.profile.data.cont_status !== null) {
				setOpen("openmsg");
			}
		});
	};

	const VisuallyHiddenInput = styled("input")({
		clip: "rect(0 0 0 0)",
		clipPath: "inset(50%)",
		height: 1,
		overflow: "hidden",
		position: "absolute",
		bottom: 0,
		left: 0,
		whiteSpace: "nowrap",
		width: 1,
	});

	const [file, setFile] = useState(null);

	const signcontract = () => {
		if (file) {
			setWorking("uploading");
			const formData = new FormData();
			formData.append("sign-contract", file);
			axios.post(api.api.user, formData).then(function (res) {
				setWorking("");
				getprofile();
				snackbar(res.data.msg);
			});
		} else {
			snackbar("Please select a photo ID.");
		}
	};

	return (
		<Box className="content" pb={10}>
			<Dialog open={open === "openmsg" ? true : false} maxWidth="xs">
				<DialogContent>
					<Box>
						<Box>{profile.cont_status === "review" ? <Lottie animationData={reivewAnimation} style={{ width: 200, margin: "auto" }} /> : profile.cont_status === "declined" ? <Lottie animationData={cancelAnimation} style={{ width: 200, margin: "auto" }} /> : profile.cont_status === "active" ? <Lottie animationData={successAnimation} style={{ width: 200, margin: "auto" }} /> : null}</Box>
						<Box textAlign={"center"}>
							{profile.cont_status === "review" ? (
								<>
									<Typography fontSize={30} fontWeight={600} color={"brown"}>
										In review
									</Typography>
									<Typography fontSize={14} color={"gray"}>
										We are reviewing your identification. Please wait and check again after some time.
									</Typography>
								</>
							) : profile.cont_status === "declined" ? (
								<>
									<Typography fontSize={30} fontWeight={600} color={"red"}>
										Application is declined
									</Typography>
									<Typography fontSize={14} color={"gray"}>
										We have reviewed your identification. It's not meeting our terms and conditions.
									</Typography>
								</>
							) : (
								profile.cont_status === "active" && (
									<>
										<Typography fontSize={30} fontWeight={600} color={"#29AB87"}>
											Contract approved!
										</Typography>
										<Typography fontSize={14} color={"gray"}>
											Your application meets our terms & conditions. Now you will receive salary benefits.
										</Typography>
									</>
								)
							)}
						</Box>
						<Box textAlign={"center"} mt={2}>
							<Button color="primary" variant="contained" onClick={() => setOpen("")}>
								Ok, Go Back
							</Button>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>

			<Container>
				<Typography color={"primary.main"} fontWeight={600} mb={1}>
					Contract agreement
				</Typography>

				<Typography fontSize={12} color={"gray"}>
					This contract is signed on [Contract Signing Date] by the following parties: <br />
					Employer (Party A): [ Aircraft-MFG ] <br />
					Employee (Party B): [ID: {profile.userid}] <br />
					<br />
					WHEREAS: Party A wishes to hire party B as a [Promotion Specialist], and party B is willing to accept the position. In accordance with the relevent labor laws and regulations of India, the agree to abide by the following terms and conditions: <br />
					<br />
					<span style={{ fontWeight: "bold" }}>1. Position and Responsibilities</span>
					<br />
					Party B will be serve as [Promotion Specialist]. Its main responsibilities and work content:
					<br />
					Explain the company's operating model.
					<br />
					Promote company products and services. <br />
					<br />
					<span style={{ fontWeight: "bold" }}>2. Contract period</span>
					<br />
					This contract is effective from [start date] and ends on [end date] unless earlier terminated in accordance with this Contract.
					<br />
					<br />
					<span style={{ fontWeight: "bold" }}>3. Salary and Benefits</span>
					<br />
					Party B's basic salary is [weekly salary]. In addition, party B will be also enjoy the following benefits: [investment income, invitation rewards, team commission, etc.].
					<br />
					<br />
					Party A (Employer):
					<br />
					Sign: Aircraft-MFG <br />
					date: {formattedDate}
					<br />
					<br />
					Party A (Employee):
					<br />
					Sign: {profile.userid} <br />
					date: {formattedDate}
				</Typography>

				<Box mt={2}>
					<Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUpload />} disabled={profile.cont_status === "review" ? true : false}>
						Select Aadhaar card for verification
						<VisuallyHiddenInput type="file" accept=".jpeg, .jpg" onChange={(event) => setFile(event.target.files[0])} />
					</Button>
					<Button variant="contained" color="primary" sx={{ width: "100%", mt: 2 }} startIcon={<Create />} disabled={profile.cont_status === "review" ? true : working === "uploading" ? true : false} onClick={() => signcontract()}>
						{working === "uploading" ? <CircularProgress color="inherit" size={24} /> : "Sign Contract"}
					</Button>
				</Box>
			</Container>
		</Box>
	);
}
