import { ContactPage, Login, Menu, ShoppingBag } from "@mui/icons-material"
import { Button, Chip, Container, Drawer, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import React, { useState } from "react"
import { NavLink, Outlet } from "react-router-dom"

export default function Header() {
	const [open, setOpen] = useState(false)

	const links = [
		{ name: "Orders", icon: <ShoppingBag />, link: "/" },
		{ name: "Contact us", icon: <ContactPage />, link: "/" },
		{ name: "Login", icon: <Login />, link: "/" },
	]

	return (
		<Box>
			<AppBar color="inherit" elevation={1} position="sticky">
				<Toolbar>
					<Typography fontSize={20} className="font-anta">
						Aircraft-MFG
					</Typography>
					<Typography sx={{ flexGrow: 1 }} />

					<Stack direction={"row"} display={{ xs: "none", md: "flex" }}>
						{links.map((row, i) => (
							<Button key={i} color="inherit" startIcon={row.icon}>
								{row.name}
							</Button>
						))}
					</Stack>
					<Box sx={{ display: { xs: "flex", md: "none" } }}>
						<IconButton onClick={() => setOpen(true)} edge="end" color="inherit">
							<Menu />
						</IconButton>
					</Box>
				</Toolbar>
			</AppBar>
			<Drawer PaperProps={{ style: { borderTopLeftRadius: "15px", borderTopRightRadius: "15px" } }} anchor="bottom" open={open} onClose={() => setOpen(false)}>
				<List>
					{links.map((row, i) => (
						<ListItem key={i}>
							<ListItemButton>
								<ListItemIcon>{row.icon}</ListItemIcon>
								<ListItemText primary={row.name} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Drawer>
			<Outlet />

			<Box backgroundColor={"#f9f9f9"}>
				<Container sx={{ pt: 3, pb: 5 }}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<Typography variant="h4" className="font-anta">
								Aircraft-MFG
							</Typography>
							<Typography mt={2} variant="caption">
								we’re dedicated to bringing you the best shopping experience with a curated <br /> selection of high-quality products, unbeatable prices, <br /> and exceptional customer service.
							</Typography>

							<Typography variant="h5" fontWeight={"bold"} mt={3}>
								Shop With Confidence
							</Typography>
							<Stack direction={"row"} useFlexGap flexWrap={"wrap"} gap={1} mt={1}>
								<Chip sx={{ borderRadius: 1 }} label="Secure Payment Options" />
								<Chip sx={{ borderRadius: 1 }} label="100% Satisfaction Guarantee" />
								<Chip sx={{ borderRadius: 1 }} label="Fast & Reliable Shipping" />
							</Stack>

							<Stack fontSize={12} direction={"row"} useFlexGap flexWrap={"wrap"} gap={1} mt={3}>
								<NavLink style={{ color: "black" }} to={"/"}>
									Terms & Conditions
								</NavLink>
								<NavLink style={{ color: "black" }} to={"/"}>
									Privacy Policy
								</NavLink>
								<NavLink style={{ color: "black" }} to={"/"}>
									Return Policy
								</NavLink>
								<NavLink style={{ color: "black" }} to={"/"}>
									Shipping Policy & Refund Policy
								</NavLink>
							</Stack>
						</Grid>
						<Grid item xs={12} md={6}>
							<Box textAlign={{ xs: "left", md: "right" }}>
								<Typography variant="h5" fontWeight={"bold"}>
									Office Address
								</Typography>
								<Typography mt={2} variant="body2" lineHeight={2}>
									Banka, Bihar, India - 800001
									<br />
									Phone: +91 1234567890
									<br />
									Email: contact@aircrafgmfg.in
								</Typography>
							</Box>
						</Grid>
					</Grid>
					<Box textAlign={"center"} mt={2}>
						<Typography variant="caption">© 2023 Aircraft-MFG. All rights reserved</Typography>
					</Box>
				</Container>
			</Box>
		</Box>
	)
}
