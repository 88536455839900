import React, { useContext, useState } from "react";

// MUI
import { Done } from "@mui/icons-material";
import { Box, Button, Chip, CircularProgress, Container, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import MyContext from "../context/context";

export default function RechargeUser({ snackbar }) {
	const [action, setAction] = useState("");
	const api = useContext(MyContext);
	const [amount, setAmount] = useState(1500);

	const create_recharge_order = () => {
		setAction("creating");
		var formData = new FormData();
		formData.append("create-recharge-order-2", amount);
		axios.post(api.api.user, formData).then(function (res) {
			setAction("");
			snackbar(res.data.message);
			if (res.data.status === true) {
				window.location.href = res.data.result.payment_url;
			}
		});
	};

	return (
		<Box className="content">
			<Container>
				<Box>
					<Typography color={"primary.main"} fontWeight={600} mb={1}>
						Enter recharge amount
					</Typography>
					<TextField fullWidth variant="outlined" label="Recharge amount" type="number" value={amount} onChange={(event) => setAmount(event.target.value)} />
				</Box>
				<Stack mt={2} spacing={1} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
					<Chip label="300" color={300 === amount ? "primary" : "default"} style={{ width: "100%" }} icon={<Done />} onClick={() => setAmount(300)} />
					<Chip label="700" color={700 === amount ? "primary" : "default"} style={{ width: "100%" }} icon={<Done />} onClick={() => setAmount(700)} />
				</Stack>
				<Stack mt={2} spacing={1} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
					<Chip size="large" color={1500 === amount ? "primary" : "default"} label="1500" style={{ width: "100%" }} icon={<Done />} onClick={() => setAmount(1500)} />
					<Chip label="2000" color={2000 === amount ? "primary" : "default"} style={{ width: "100%" }} icon={<Done />} onClick={() => setAmount(2000)} />
				</Stack>
				<Stack mt={2} spacing={1} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
					<Chip size="large" color={5000 === amount ? "primary" : "default"} label="5000" style={{ width: "100%" }} icon={<Done />} onClick={() => setAmount(5000)} />
					<Chip label="10000" color={10000 === amount ? "primary" : "default"} style={{ width: "100%" }} icon={<Done />} onClick={() => setAmount(10000)} />
				</Stack>

				<Stack mt={3} direction={"column"} spacing={1}>
					<Button variant="contained" size="large" disabled={action === "creating" ? true : false} onClick={() => create_recharge_order()}>
						{action === "creating" ? <CircularProgress color="inherit" size={24} /> : "Recharge 1"}
					</Button>
				</Stack>

				<Box mt={2}>
					<Typography fontWeight={500} color={"secondary.main"} mb={0.5}>
						Information,
					</Typography>
					<Typography color={"gray"} fontSize={12}>
						1. Minimum recharge amount is 300 &#8377;
					</Typography>
				</Box>
			</Container>
		</Box>
	);
}
