import { Delete } from "@mui/icons-material";
import { Alert, Box, Button, Container, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Ranking({ snackbar }) {
	const apiUrl = process.env.REACT_APP_API;
	const [action, setAction] = useState("");
	const [open, setOpen] = useState("");
	const [formValues, setFormValues] = useState({
		userId: "",
		state: "",
		city: "",
		listNumber: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
	};

	const handleSubmit = async () => {
		try {
			setAction("updating");
			let formData = new FormData();
			formData.append("update-ranking", formValues.userId);
			formData.append("state", formValues.state);
			formData.append("city", formValues.city);
			formData.append("rank", formValues.listNumber);
			const response = await axios.post(`${apiUrl}/admin`, formData);
			if (response.data.status === true) {
				get_rank_list();
				setFormValues({
					userId: "",
					state: "",
					city: "",
					listNumber: "",
				});
			}
			snackbar(response.data.msg);
		} catch (error) {
			console.log(error);
		} finally {
			setAction("");
		}
	};

	const delete_rank = async () => {
		try {
			setAction("deleting");
			let formData = new FormData();
			formData.append("delete-ranking", formValues.userId);
			const response = await axios.post(`${apiUrl}/admin`, formData);
			if (response.data.status === true) {
				get_rank_list();
				setOpen("");
			}
			snackbar(response.data.msg);
		} catch (error) {
			console.log(error);
		} finally {
			setAction("");
		}
	};

	useEffect(() => {
		get_rank_list();
	}, []);

	const [rankList, setRankList] = useState([]);
	const get_rank_list = async () => {
		try {
			const response = await axios.get(`${apiUrl}/admin?ranking`);
			if (response.data.status === true) {
				setRankList(response.data.data);
			} else {
				setRankList(response.data);
			}
		} catch (error) {
			console.log(error);
		} finally {
		}
	};

	return (
		<Box className="content">
			<Dialog fullWidth maxWidth="xs" open={open === "delete" ? true : false} onClose={() => setOpen(false)}>
				<DialogContent>
					<Box textAlign={"center"}>
						<Delete sx={{ fontSize: 70 }} color="error" />
						<Typography fontWeight={"bold"} variant="h5">
							Are you sure you want to delete this ranking?
						</Typography>
						<Typography mt={1} variant="body2" color="gray">
							You can't undo this action.
						</Typography>
						<Stack direction={"column"} mt={3} gap={1}>
							<Button disabled={action === "deleting" ? true : false} variant="contained" color="error" onClick={() => delete_rank(open)}>
								Delete
							</Button>
							<Button variant="outlined" onClick={() => setOpen(false)}>
								Cancel
							</Button>
						</Stack>
					</Box>
				</DialogContent>
			</Dialog>
			<Container>
				<Box
					component="form"
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 1,
						margin: "0 auto",
					}}
				>
					<TextField fullWidth type="number" label="User ID" name="userId" value={formValues.userId} onChange={handleChange} variant="outlined" />
					<Stack direction={"row"} gap={1}>
						<TextField fullWidth label="State" name="state" value={formValues.state} onChange={handleChange} variant="outlined" />
						<TextField fullWidth label="City" name="city" value={formValues.city} onChange={handleChange} variant="outlined" />
					</Stack>
					<FormControl fullWidth variant="outlined">
						<InputLabel id="list-number-label">List Number</InputLabel>
						<Select labelId="list-number-label" id="list-number" name="listNumber" value={formValues.listNumber} onChange={handleChange} label="List Number">
							{/* Add your options here */}
							<MenuItem value={1}>Rank 1</MenuItem>
							<MenuItem value={2}>Rank 2</MenuItem>
							<MenuItem value={3}>Rank 3</MenuItem>
							<MenuItem value={4}>Rank 4</MenuItem>
							<MenuItem value={5}>Rank 5</MenuItem>
							<MenuItem value={6}>Rank 6</MenuItem>
							<MenuItem value={7}>Rank 7</MenuItem>
							<MenuItem value={8}>Rank 8</MenuItem>
							<MenuItem value={9}>Rank 9</MenuItem>
							<MenuItem value={10}>Rank 10</MenuItem>
						</Select>
					</FormControl>
					<Button variant="contained" onClick={handleSubmit} disabled={action === "updating" ? true : false}>
						Upload
					</Button>
				</Box>

				<Box mt={3}>
					{rankList.status === false ? (
						<Alert severity="error">{rankList.msg}</Alert>
					) : (
						<Box>
							<TableContainer component={Paper}>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell align="left">Rank No.</TableCell>
											<TableCell align="left">Name</TableCell>
											<TableCell align="left">User ID</TableCell>
											<TableCell align="left">Address</TableCell>
											<TableCell align="right"></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{rankList.map((row, i) => {
											return (
												<TableRow key={i}>
													<TableCell align="left" component="th" scope="row">
														{row.rank}
													</TableCell>
													<TableCell align="left">{row.name}</TableCell>
													<TableCell align="left">{row.userid}</TableCell>
													<TableCell align="left">
														{row.state}, {row.city}
													</TableCell>
													<TableCell align="right">
														<Button
															size="small"
															variant="outlined"
															color="error"
															onClick={() => {
																setOpen("delete");
																setFormValues((prevValues) => ({
																	...prevValues, // Keep the existing values
																	userId: row.userid, // Update only the userId
																}));
															}}
														>
															Delete
														</Button>
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					)}
				</Box>
			</Container>
		</Box>
	);
}
