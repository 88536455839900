import React, { useContext, useEffect, useState } from "react";
import {} from "react-router-dom";

// MUI
import { CurrencyRupee, Search } from "@mui/icons-material";
import { Alert, Box, Button, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, Divider, Grid, IconButton, Paper, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import MyContext from "../context/context";

export default function WithdrawRequestAdmin({ snackbar }) {
	const [open, setOpen] = useState("");
	const [action, setAction] = useState("");

	const api = useContext(MyContext);

	const [search, setSearch] = useState("");
	useEffect(() => {
		withdraw_request(search);
	}, []);

	const [request, setRequest] = useState([]);
	const withdraw_request = (search) => {
		axios.get(`${api.api.admin}?withdraw-request=${search}`).then(function (res) {
			res.data.status === true ? setRequest(res.data.data) : setRequest(res.data);
		});
	};

	const handle_search = (event) => {
		setSearch(event.target.value);
		if (event.key === "Enter") {
			withdraw_request(event.target.value);
		}
	};

	const [userid, setUserid] = useState("");
	const [tableid, setTableid] = useState("");
	const [alert, setAlert] = useState("");
	const open_request_dialog = (userid, tableid) => {
		setUserid(userid);
		setTableid(tableid);
		setOpen("withdraw");
	};

	const confirm_request = () => {
		setAction("withdraw");
		var formData = new FormData();
		formData.append("confirm-withdraw-request", userid);
		formData.append("tableid", tableid);
		axios.post(api.api.admin, formData).then(function (res) {
			snackbar(res.data.msg);
			setAction("");
			setOpen("");
			res.data.status && withdraw_request(search);
		});
	};

	return (
		<Box className="content" pb={10}>
			<Dialog open={open === "withdraw" ? true : false} fullWidth maxWidth="xs" onClose={() => setOpen("")}>
				<DialogContent>
					<Typography fontSize={25} color={"error.main"} fontWeight={600}>
						Do you want to confirm this request?
					</Typography>
					<Typography color={"gray"} fontSize={14}>
						*Remember, you can't undo this process.
					</Typography>
					<Typography color={"gray"} fontSize={14}>
						*Did you transfered the amount to the user bank account.
					</Typography>

					<Typography mb={0.5} fontSize={12} color={"gray"} mt={2}>
						To confirm this request please type 'confirm' below
					</Typography>
					<TextField fullWidth variant="outlined" label="Type confirm" value={alert} onChange={(event) => setAlert(event.target.value)} />
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button color="inherit" onClick={() => setOpen("")}>
						Close
					</Button>
					<Button variant="contained" color="primary" onClick={() => confirm_request()} disabled={action === "withdraw" ? true : alert === "confirm" ? false : true}>
						{action === "withdraw" ? <CircularProgress color="inherit" size={24} /> : "Confirm"}
					</Button>
				</DialogActions>
			</Dialog>
			<Container>
				<TextField
					variant="standard"
					placeholder="Search by Name, ID or Mobile..."
					fullWidth
					onKeyDown={(event) => handle_search(event)}
					InputProps={{
						endAdornment: (
							<IconButton position="end">
								<Search />
							</IconButton>
						),
					}}
				/>
				<Box mt={3}>
					{request.status === false ? (
						<Alert severity="warning">{request.msg}</Alert>
					) : (
						request.map((row, i) => (
							<Box mt={1} key={i} component={Paper} padding={2} onClick={() => open_request_dialog(row.userid, row.requestid)}>
								<Grid container mb={1}>
									<Grid item xs={6} alignItems={"center"}>
										<Box>
											<Typography letterSpacing={2} variant="h6" color={"primary.main"} fontWeight={"bold"}>
												{row.userid}
											</Typography>
											<Typography fontSize={12} color={"gray"}>
												{row.name}
											</Typography>
											<Typography fontSize={12} color={"gray"}>
												{new Date(row.date).toLocaleDateString(undefined, { day: "numeric", month: "long", year: "numeric" })}
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Stack textAlign={"right"} direction={"row"} justifyContent={"end"} spacing={2}>
											<Box>
												<Typography variant="caption" color={"gray"}>
													Requested
												</Typography>
												<Typography />
												<Chip sx={{ borderRadius: 1 }} color="default" variant="outlined" icon={<CurrencyRupee />} label={Number(row.amount).toLocaleString("en-in")} />
											</Box>
											<Box>
												<Typography variant="caption" color={"gray"}>
													Payable
												</Typography>
												<Typography />
												<Chip sx={{ borderRadius: 1, fontWeight: "bolder", fontSize: 20 }} color="error" variant="outlined" icon={<CurrencyRupee />} label={(Number(row.amount) - (Number(row.amount) * 18) / 100).toLocaleString("en-in")} />
											</Box>
										</Stack>
									</Grid>
								</Grid>
								<Divider />
								<Typography mt={1} fontSize={10} color={"gray"}>
									Payable amount is calculated on the basis of 18% GST
								</Typography>
							</Box>
						))
					)}
				</Box>
			</Container>
		</Box>
	);
}
