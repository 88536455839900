import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MyContext from "../context/context";

// MUI
import { AccountTree, ContentCopy, Diversity1, Edit, Launch, Payment, Search } from "@mui/icons-material";
import { Box, Button, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, Input, InputAdornment, InputLabel, Menu, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import axios from "axios";

import InfiniteScroll from "react-infinite-scroll-component";

export default function UserAdmin({ snackbar }) {
	const api = useContext(MyContext);
	const [open, setOpen] = useState("");
	const [action, setAction] = useState("");
	const navi = useNavigate();

	var url = new URLSearchParams(window.location.search).get("value");
	const [searchquery, setSearchquery] = useState(url ? url : "");

	const [users, setUsers] = useState([]);
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(0);
	const [usercount, setUsercount] = useState(0);
	useEffect(() => {
		get_users(page);
	}, [page]);
	const get_users = (page) => {
		axios.get(`${api.api.admin}?users=${searchquery}&offset=${page}`).then(function (res) {
			if (page > 0) {
				if (res.data.users.status === true) {
					const newdata = res.data.users.data;
					setUsers((pre) => [...pre, ...newdata]);
				} else {
					setHasMore(false);
				}
			} else if (res.data.users.status === true) {
				setUsers(res.data.users.data);
			} else {
				setUsers(res.data.users);
			}
			setUsercount(res.data.usercount.data);
		});
	};

	const loadmore = () => {
		setPage((page) => page + 1);
	};

	const [userid, setUserid] = useState("");
	const [name, setName] = useState("");
	const [mobile, setMobile] = useState("");
	const [status, setStatus] = useState("");
	const open_userdialog = (userid) => {
		setUserid(userid);
		setOpen("userdialog");
		get_user_details(userid);
	};
	const get_user_details = (userid) => {
		axios.get(`${api.api.admin}?user-details=${userid}`).then(function (res) {
			snackbar(res.data.msg);
			if (res.data.status === true) {
				setName(res.data.data.name);
				setMobile(res.data.data.mobile);
				setStatus(res.data.data.status);
			}
		});
	};

	const update_user_details = () => {
		setAction("updating");
		var formData = new FormData();
		formData.append("update-user", userid);
		formData.append("name", name);
		formData.append("mobile", mobile);
		formData.append("status", status);
		axios.post(api.api.admin, formData).then(function (res) {
			setAction("");
			snackbar(res.data.msg);
			if (res.data.status === true) {
				get_users(0);
				setOpen("");
			}
		});
	};

	const search_user = (event) => {
		if (event.key === "Enter") {
			navi(`?value=${event.target.value}`, { replace: true });
			get_users(0);
		}
	};

	const [bank, setBank] = useState([]);
	const get_bank_details = (userid) => {
		setBank([]);
		setOpen("bank");
		axios.get(`${api.api.admin}?user-bank-details=${userid}`).then(function (res) {
			snackbar(res.data.msg);
			res.data.status === true && setBank(res.data.data);
		});
	};

	const handleCopy = (event) => {
		navigator.clipboard
			.writeText(event)
			.then(() => snackbar("Copied to clipboard"))
			.catch(() => snackbar("Failed to copy"));
	};

	const open_recharge = (userid) => {
		setUserid(userid);
		setOpen("recharge");
	};

	const [amount, setAmount] = useState("");
	const manually_recharge = () => {
		setAction("recharge");
		var formData = new FormData();
		formData.append("recharge-to-the-user-account", userid);
		formData.append("amount", amount);
		axios.post(api.api.admin, formData).then(function (res) {
			setAction("");
			snackbar(res.data.msg);
			if (res.data.status === true) {
				setAmount("");
				setOpen("");
			}
		});
	};

	const [stuid, setStuid] = useState("");
	const [anchorEl, setAnchorEl] = useState(null);
	const openmenu = (event, stuid) => {
		setStuid(stuid);
		setAnchorEl(event.currentTarget);
	};

	return (
		<Box className="content" mb={{ xs: 10, md: 0 }}>
			<Container>
				<Menu id="basic-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
					<MenuItem onClick={() => get_bank_details(stuid)}>Bank details</MenuItem>
					<MenuItem onClick={() => navi(`/admin/purchase?value=${stuid}`)}>Purchase</MenuItem>
					<MenuItem onClick={() => navi(`/admin/recharge?value=${stuid}`)}>Recharge</MenuItem>
					<MenuItem onClick={() => navi(`/admin/user-team?userid=${stuid}`)}>Teams</MenuItem>
				</Menu>

				<Dialog open={open === "userdialog" ? true : false} fullWidth maxWidth="xs" onClose={() => setOpen("")}>
					<DialogTitle>User detail</DialogTitle>
					<Divider />
					<DialogContent>
						<Stack spacing={1.2}>
							<TextField fullWidth variant="outlined" label="Name" value={name} onChange={(event) => setName(event.target.value)} type="text" />
							<TextField fullWidth variant="outlined" label="Mobile" value={mobile} onChange={(event) => setMobile(event.target.value)} type="number" />
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">Status</InputLabel>
								<Select labelId="demo-simple-select-label" id="demo-simple-select" value={status} label="Status" onChange={(event) => setStatus(event.target.value)}>
									<MenuItem value={"active"}>Active</MenuItem>
									<MenuItem value={"pending"}>Pending</MenuItem>
									<MenuItem value={"blocked"}>Blocked</MenuItem>
								</Select>
							</FormControl>
						</Stack>
					</DialogContent>
					<Divider />
					<DialogActions>
						<Button variant="outlined" color="inherit" onClick={() => setOpen("")}>
							Close
						</Button>
						<Button variant="contained" color="primary" disabled={action === "updating" ? true : false} onClick={() => update_user_details()}>
							{action === "updating" ? <CircularProgress color="inherit" size={24} /> : "Update"}
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog open={open === "bank" ? true : false} fullWidth maxWidth="xs" onClose={() => setOpen("")}>
					<DialogContent>
						<Stack mb={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
							<Box>
								<Typography fontSize={10} color={"gray"}>
									Account holder name
								</Typography>
								<Typography fontSize={14} color={"primary.main"} fontWeight={500}>
									{bank.name}
								</Typography>
							</Box>
							<IconButton onClick={() => handleCopy(bank.name)}>
								<ContentCopy />
							</IconButton>
						</Stack>
						<Divider />
						<Stack mt={1} mb={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
							<Box>
								<Typography fontSize={10} color={"gray"}>
									Bank name
								</Typography>
								<Typography fontSize={14} color={"primary.main"} fontWeight={500}>
									{bank.bank_name}
								</Typography>
							</Box>
							<IconButton onClick={() => handleCopy(bank.bank_name)}>
								<ContentCopy />
							</IconButton>
						</Stack>
						<Divider />
						<Stack mt={1} mb={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
							<Box>
								<Typography fontSize={10} color={"gray"}>
									IFSC
								</Typography>
								<Typography fontSize={14} color={"primary.main"} fontWeight={500}>
									{bank.ifsc}
								</Typography>
							</Box>
							<IconButton onClick={() => handleCopy(bank.ifsc)}>
								<ContentCopy />
							</IconButton>
						</Stack>
						<Divider />
						<Stack mt={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
							<Box>
								<Typography fontSize={10} color={"gray"}>
									Account number
								</Typography>
								<Typography fontSize={14} color={"primary.main"} fontWeight={500}>
									{bank.account}
								</Typography>
							</Box>
							<IconButton onClick={() => handleCopy(bank.account)}>
								<ContentCopy />
							</IconButton>
						</Stack>
					</DialogContent>
				</Dialog>

				<Dialog open={open === "recharge" ? true : false} fullWidth maxWidth="xs" onClose={() => setOpen("")}>
					<DialogTitle>Manually recharge</DialogTitle>
					<Divider />
					<DialogContent>
						<TextField fullWidth variant="outlined" type="number" label="Recharge amount" value={amount} onChange={(event) => setAmount(event.target.value)} />
						<Box textAlign={"center"} mt={2}>
							<Button variant="contained" color="primary" sx={{ width: "100%" }} onClick={() => manually_recharge()} disabled={action === "recharge" ? true : false}>
								{action === "recharge" ? <CircularProgress color="inherit" size={24} /> : "Recharge"}
							</Button>
						</Box>
						<Box mt={2}>
							<Typography mb={1} color={"secondary.main"} fontWeight={"bold"}>
								Information
							</Typography>
							<Typography fontSize={12} color={"gray"}>
								1. Remember, you can't undo this process.
							</Typography>
							<Typography fontSize={12} color={"gray"}>
								2. Please use this feature when the user transaction is failed due to technical reason.
							</Typography>
						</Box>
					</DialogContent>
				</Dialog>

				<FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
					<InputLabel htmlFor="outlined-adornment-password">Search user</InputLabel>
					<Input
						id="outlined-adornment-password"
						type="text"
						placeholder="Search by user ID, name, mobile..."
						endAdornment={
							<InputAdornment position="end">
								<IconButton aria-label="toggle password visibility" edge="end" onClick={() => search_user()}>
									<Search />
								</IconButton>
							</InputAdornment>
						}
						label="Search user"
						value={searchquery}
						onChange={(event) => setSearchquery(event.target.value)}
						onKeyUp={(event) => search_user(event)}
					/>
				</FormControl>

				<Chip label={`Team members are: ${usercount.users}`} color="primary" icon={<Diversity1 />} />
				<TableContainer component={Paper} sx={{ mt: 2 }}>
					<InfiniteScroll dataLength={users.length} next={loadmore} hasMore={hasMore} loader={<h4>Loading...</h4>} endMessage={<p style={{ textAlign: "center" }}>No more data to load</p>}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>#</TableCell>
									<TableCell align="left">User ID</TableCell>
									<TableCell align="left">Name</TableCell>
									<TableCell align="left">Mobile</TableCell>
									<TableCell align="left">Status</TableCell>
									<TableCell align="left">Details</TableCell>
									<TableCell align="right">Action</TableCell>
									<TableCell align="right">Recharge</TableCell>
									<TableCell align="right">Wallet</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{users.status !== false &&
									users.map((row, i) => (
										<TableRow key={i}>
											<TableCell align="left">{i + 1}</TableCell>
											<TableCell align="left">{row.userid}</TableCell>
											<TableCell align="left">{row.name}</TableCell>
											<TableCell align="left">{row.mobile}</TableCell>
											<TableCell align="left">
												<Chip variant="outlined" color={row.status === "active" ? "primary" : row.status === "pending" ? "warning" : row.status === "blocked" ? "error" : "default"} label={row.status} sx={{ textTransform: "capitalize" }} />
											</TableCell>
											<TableCell align="left">
												<Chip variant="contained" color="secondary" label="Details" clickable icon={<AccountTree />} onClick={(event) => openmenu(event, row.userid)} />
											</TableCell>
											<TableCell align="right">
												<Chip variant="contained" color="primary" label="Edit" clickable icon={<Edit />} onClick={() => open_userdialog(row.userid)} />
											</TableCell>
											<TableCell align="right">
												<Chip variant="contained" color="success" label="Recharge" clickable icon={<Payment />} onClick={() => open_recharge(row.userid)} />
											</TableCell>
											<TableCell align="right">
												<Chip variant="contained" color="info" label="Wallet" clickable icon={<Launch />} onClick={() => navi(`/admin/wallet-history?userid=${row.userid}`)} />
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</InfiniteScroll>
				</TableContainer>
			</Container>
		</Box>
	);
}
