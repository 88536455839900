import { ShoppingBag } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

const ProductDetails = () => {
	const [quantity, setQuantity] = useState(1);
	const [selectedColor, setSelectedColor] = useState("");

	const handleQuantityChange = (type) => {
		setQuantity((prev) => (type === "increment" ? prev + 1 : Math.max(prev - 1, 1)));
	};

	return (
		<Box sx={{ p: 4 }}>
			<Grid container spacing={4}>
				{/* Left Section: Product Images */}
				<Grid item xs={12} md={6}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 2,
							alignItems: "center",
						}}
					>
						<Box sx={{ display: "flex", gap: 1 }}>
							<Box
								component="img"
								src={"../img/shop_img/pendrive.png"}
								alt="Thumbnail 1"
								sx={{
									padding: 2,
									borderRadius: 2,
									cursor: "pointer",
									backgroundColor: "#F3F4F6",
								}}
							/>
						</Box>
					</Box>
				</Grid>

				<Grid item xs={12} md={6}>
					<Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
						Magnus Gothard Leather Strap
					</Typography>
					<Typography variant="h5" fontWeight={"bold"} sx={{ mb: 2 }}>
						&#8377; {Number(3455).toLocaleString()}
					</Typography>
					<Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
						It has been updated with a seconds hand and days window. The watch features ceramic dials, three-hand movement as well as a leather strap.
					</Typography>

					<Typography variant="body2">Product Color</Typography>
					<Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
						Black
					</Typography>

					<Box mt={3}>
						<Button variant="contained" size="large" color="primary" startIcon={<ShoppingBag />}>
							Buy Now
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ProductDetails;
